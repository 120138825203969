/* Base Styles */
.container {
  text-align: center;
  padding-top: 15rem;
  font-family: initial;
  height: 42rem;
}

.handleHeading h3 {
  font-weight: 600;
  padding-bottom: 1.8rem;
}

.handleInput input {
  width: 30rem;
  padding: 0.5rem;
  /* border: 1px solid #0383b0; */
}

.handleBtn {
  margin-left: 26.5rem;
  padding: 0.3rem 1.3rem 0.3rem 1.3rem;
  margin-left: 28.5rem;
  background-color: #0383b0;
  border: #0383b0;
  box-shadow: 6px 8px 20px #000000a3;
  border-radius: 6px;
}
.input {
  border: px solid red !important;
}
.handleInput div div {
  padding: 0.5rem;
}
/* Table Container Styles */
.table-container {
  width: 100%;
  margin: 0 auto;
}

/* Table Styles */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  margin-top: 20px;
}

/* Table Header Styles */
.custom-table th {
  font-weight: bold;
  text-align: left;
  padding: 10px;
}

/* Table Row Styles */
.custom-table tr {
  border-bottom: 1px solid #ccc;
}

/* Table Cell Styles */
.custom-table td {
  padding: 10px;
}

/* Highlighted Row Styles */
.custom-table tr.highlighted {
  background-color: #e0f7fa;
}

.handleUl {
  margin-bottom: 1rem;
  display: flex;
  list-style: none;
}

.handleUl li:hover {
  background-color: #0383b0;
  color: #fff;
}

.handleLabel {
  margin-left: 1.8rem;
  font-size: 1rem;
  font-weight: bolder;
}

.hanleList {
  border-left: 1px solid #0383b0;
  padding-left: 2rem;
  padding-right: 2rem;
}

.handletableRow {
  background-color: #0383b0 !important;
  color: #fff;
}

.handleHeader {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 2rem;
  font-family: initial;
  font-weight: bolder;
}

.handleSideBar {
  font-family: initial !important;
  color: #0383b0;
}
.handleImg {
  background-image: url("./component/images/Sample-tool-Document-1\ \(1\).jpg");
  background-size: cover;
}

table tr {
  font-family: initial;
  font-size: 1.3rem;
}
.custom-font-family li div span {
  font-family: initial !important;
  font-size: 23px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .container {
    margin-top: 8%;
  }

  .handleInput input {
    width: 100%;
  }

  .handleBtn {
    background-color: #0383b0;
    border: #0383b0;
    box-shadow: 7px 8px 27px #000;
  }
}

.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root:hover {
  background-color: #0383b0 !important;
  color: #fff;
}
